.edit_content_container{
    border:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    padding: 15px;
    font-family: "Noto Serif",serif !important;
    font-size: 17px !important;
}

.edit_content_header{
    padding: 5px;
    background: var(--bs-border-color);
}
