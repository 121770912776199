.dragg {
    cursor: move;
}

.modal.show {
    display: block;
}

.modal-full {
    --bs-modal-width: 100%;
    --bs-modal-margin: 0px;
    border-radius: 0;
}

.modal-full .modal-dialog {
    min-height: 100vh;
    margin: 0;
}

.modal-full .modal-content {
    min-height: 100vh;
}

.modal-body {
    max-height: calc(100vh - 185px);
    overflow: auto;
}

.modal-full .modal-body {
    max-height: calc(100vh - 128px);
    overflow: auto;
}