.dx-datagrid-headers .dx-header-row {
    background: var(--bs-gray-200);
}

td[role=columnheader] {
    text-align: center !important;
    font-size: 13px;
    color: #333;
    font-weight: 600;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
    background-color: rgb(210, 255, 240);
    color: #333;
}

.nk-error-gfx-401 {
    width: 320px;
    margin: auto;
    margin-bottom: 25px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
    vertical-align: middle;
}
.dx-datagrid-rowsview .dx-row.dx-group-row td {
    background: #e0e0e0;
    border-bottom: 1px solid #fff;
}

.dx-datagrid .dx-row>td {
    padding: 5px 6px;
    font-size: 12px;
    font-weight: 500;
}
.dx-datagrid-headers .dx-texteditor-input, .dx-datagrid-rowsview .dx-texteditor-input {
    padding: 2px;
    min-height: unset;
}

.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
    padding: 2px;
}

.dx-button-has-text .dx-button-content {
    padding: 2px 6px;
}

.dx-button-has-text .dx-icon {
    width: 14px;
    height: 14px;
    font-size: 14px;
    margin-right: 9px;
    margin-left: 0;
}

.dx-button-has-text .dx-button-content .dx-button-text{
  font-size: 12px;
}