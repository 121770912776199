/*!
 * Theme Name: Default
 * Package : DashLite
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version	 :	1.0.0
 * Updated	 :	07.23.2020
**/
.dropdown-menu.show {
    top: 100%;
    right: 0;
}

.nk-content {
    padding: 0;
}

.page-title {
    font-size: 1.2rem;
}

.form-control.form-control-sm {
    min-height: calc(1.75rem + 2px);
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
    border-radius: 3px;
}

.form-control.form-control-xs {
    min-height: calc(1.25rem + 2px);
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
    border-radius: 3px;
}

.nk-tb-item .nk-tb-col:not(:last-child) {
    border-right: 1px solid rgba(219, 223, 234, 0.9);
}

.nk-tb-head .nk-tb-col:not(:last-child) {
    border-right: 1px solid rgba(219, 223, 234, 0.9);
}

.nk-tb-foot .nk-tb-col {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #8094ae;
    font-size: 0.9em;
    border-top: 1px solid rgba(219, 223, 234, 0.9);
}

.nk-tb-head .nk-tb-col {
    text-align: center;
}

.link-list-opt button:hover {
    color: #00a651;
    background: #f5f6fa;
}

.link-list-opt button {
    display: flex;
    align-items: center;
    padding: 0.625rem 1.125rem;
    font-size: 12px;
    font-weight: 500;
    color: #526484;
    transition: all 0.4s;
    line-height: 1.3rem;
    position: relative;
}

.nk-content-overflow {
    position: relative;
    min-height: calc(100vh - (75px));
    max-height: calc(100vh - (75px));
    overflow: hidden;
    display: flex;
}

.nk-content-view {
    width: 100%;
    display: block;
}

.article-avatar {
    height: 60px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #3fbd9a;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06em;
    flex-shrink: 0;
    position: relative;
    margin-right: 1rem;
    text-align: center;
}

.article-avatar img {
    width: auto;
    height: 100%;
}

.user-card .user-info {
    color: #626161;
    width: 100%;
}

h6.tb-lead {
    font-size: 14px;
    font-weight: 600;
    padding-top: 6px;
}

.tb-sapo {
    font-size: 13px;
    color: #8094ae;
}

.ck-content .video video {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
}

.ck-content .side-card {
    padding: 0.5em;
    border-radius: 8px;
    width: 300px;
    margin: 1em auto 1em 1.5em;
    font-size: 0.9em;
    float: right;
}

.ck-content .side-card.ck-widget.ck-widget_with-selection-handle .ck-widget__selection-handle {
    transform: translateY(calc(-100% - 7px));
    left: calc(0px - var(--ck-widget-outline-thickness) - 1px);
}

.ck-content .side-card-title {
    margin-bottom: 0.5em;
    font-size: 1.2em;
    color: inherit;
    border-bottom-color: inherit;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.ck-content .side-card-alternate {
    background: hsl(48, 100%, 93%);
    border: 1px solid hsl(47, 100%, 75%);
}

.ck-content .side-card-alternate .side-card-title {
    color: hsl(47, 100%, 32%);
}

.ck-content .side-card-default {
    background: hsl(205, 100%, 95%);
    border: 1px solid hsl(205, 91%, 82%);
}

.ck-content .side-card-default .side-card-title {
    color: hsl(204, 79%, 41%);
}

.ck-content .side-card-section {
    padding: 4px;
    background: hsl(0, 0%, 100%);
    border-radius: 6px;
    margin-top: 0.5em;
}

.ck-content .side-card-section p:only-child,
.ck-content .side-card-section p:last-child {
    margin-bottom: 0;
}

.ck-content .side-card-url {
    margin: 0.5em 0;
    font-style: italic;
    font-size: 0.8em;
    color: hsl(0, 0%, 20%);
}

.ck-content .side-card-actions {
    margin: 0.5em 0;
}

.ck-content .side-card-actions button {
    font-size: 0.8em;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 2px 4px;
    margin-right: 0.5em;
}

.nk-files-view-grid .nk-file .custom-control.neo-isdefault {
    top: 0;
    left: 0;
}

.edit-w {
    width: calc(100% - 380px);
}

.form-control.editor-f-22 {
    font-size: 22px;
    line-height: initial;
}

.form-control.editor-f-18 {
    font-size: 18px;
    line-height: initial;
}

.editor-focus:focus,
.editor-focus:hover,
.editor-focus:active {
    outline: none;
    border-bottom: 1px solid hsl(208, 79%, 51%);
    border-radius: 0;
}

.icon_count {
    right: 20px;
}

.editor-font * {
    font-family: "Noto Serif", serif;
}

.nk-choose-files {
    top: 0;
    background-color: #fff;
    width: 320px;
    height: 100%;
    padding: 1rem;
    z-index: 1010;
}

.neo-sticky-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #f5f6fa;
    z-index: 600;
    padding: 16px;
    padding-left: 25%;
    padding-right: 10%;
    right: 0;
    box-shadow: -2px 0 24px -2px rgb(43 55 72 / 15%);
    text-align: center;
}

.neo-table-group {
    display: table-row-group;
}

.update__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.update__chart {
    /*margin-right: 1em;*/
}

.update__chart__circle {
    transform: rotate(-90deg);
    transform-origin: center;
}

.update__chart__characters {
    font-size: 10px;
    font-weight: bold;
}

.update__words {
    flex-grow: 1;
    opacity: .5;
}

.update__limit-close .update__chart__circle {
    stroke: hsl(30, 100%, 52%);
}

.update__limit-exceeded .ck.ck-editor__editable_inline {
    background: hsl(0, 100%, 97%);
}

.update__limit-exceeded .update__chart__circle {
    stroke: hsl(0, 100%, 52%);
}

.update__limit-exceeded .update__chart__characters {
    /*fill: hsl( 0, 100%, 52% );*/
}

.fancy,
.ck.ck-button.ck-heading_heading_fancy {
    color: #ff0050;
}

.ck-content .template-box {
    display: table;
    clear: both;
    margin: 1em auto;
    width: 100%;
    background: #f5f6fa;
}

.ck-content .template-box .template-box-content {
    background: #fff;
}

.template-box {
    padding: 15px;
    box-sizing: border-box;
    text-align: left;
}

.template-box.template-default {
    background-color: #f5f7f9;
}

.template-box.template-background {
    background-color: #dbebfb;
}

.template-box.template-border {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.template-box.template-border-dashed {
    border: 1px dashed rgba(0, 0, 0, 0.125) !important;
}

.template-box.template-border-half {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.videoWrapper {
    width: 100%;
    text-align: center;
    margin: auto;
}

.videoWrapper video {
    width: 50%;
    height: auto;
}

.input_tags {
    min-height: calc(2.125rem + 2px);
}

.input_tags .tagify__input {
    min-height: calc(1.425rem + 2px);
}

.__neo_submit-BTN {
    margin: 4px;
}

.ck-heading_paragraph-style_custom {
    margin: 4px !important;
    width: calc(100% - 8px) !important;
}

.nvblock-style_one {
    background-color: #f9f879 !important;
    border-left: 6px solid #ff0000 !important;
    margin: 20px;
    padding: 4px 10px;
    box-shadow: 1px 0px 2px 1px rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}


.nvblock-style_two {
    background-color: #fef5c4 !important;
    border: solid 1px #fbe4a2 !important;
    margin: 10px;
    padding: 4px 10px;
}


.nvblock-style_three {
    background: #eee !important;
    border-top: 2px solid #009cd7 !important;
    padding: 15px 20px 5px;
}

.form-note.invalid {
    color: #e85347;
    font-size: 11px;
    font-style: italic;
}

.nk-sidebar .nk-menu-sub {
    position: relative;
    background: #faf8ff;
    padding: 0.3rem 0;
    border-radius: 0 0 6px 6px;
}



.tooltip-header {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
}

.tooltip-body {
    width: 170px;
}

.tooltip-body .series-name {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
    line-height: 1.5;
    padding-right: 10px;
    width: 126px;
}

.tooltip-body .value-text {
    display: inline-block;
    line-height: 1.5;
    width: 30px;
}
.btn-none {
    display: inline-block;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    padding: 2px;
    border-radius: 6px;
    font-weight: bold;
    margin-right: 1px;
    border: 0;
    font-size: 16px;
}

.text-link {
    color: #4a75c7;
}

.fs-18px {
    font-size: 18px !important;
}

.fs-20px {
    font-size: 20px !important;
}

.fs-22px {
    font-size: 22px !important;
}

.fs-24px {
    font-size: 24px !important;
}

.fs-28px {
    font-size: 28px !important;
}

.form-control.dot_bottom, .select-box.dot_bottom, .date-box.dot_bottom{
    border: none;
    background: none;
    font-weight: 600;
    border-bottom: 1px dashed #8091a7;
}

.form-control.line_bottom, .select-box.line_bottom, .date-box.line_bottom{
    border: none;
    background: none;
    font-weight: 600;
    border-bottom: 1px solid #8091a7;
}

.form-label.form-label-small, .custom-control-label.form-label-small{
    font-size: 11px;
    font-weight: 600;
}

.product-image-avatar {
    height: 80px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #3fbd9a;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.06em;
    flex-shrink: 0;
    position: relative;
    margin-right: 1rem;
    text-align: center;
}

.product-image-avatar img {
    width: auto;
    height: 100%;
}